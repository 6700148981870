li:nth-child(n+5) {
    color: red !important;
    display: none;   
}




.nodecor {
    text-decoration: none;
    color: black;

}

.nodecor:visited {
    text-decoration: none;
    color: black;
}